/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #ffed00;
    --ion-color-primary-rgb: 255,237,0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #e0d100;
    --ion-color-primary-tint: #ffef1a;

    --ion-color-secondary: #0796d7;
    --ion-color-secondary-rgb: 7,150,215;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #0684bd;
    --ion-color-secondary-tint: #20a1db;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82,96,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #34b88d;
    --ion-color-success-rgb: 52,184,141;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #2ea27c;
    --ion-color-success-tint: #48bf98;

    --ion-color-warning: #ff7f00;
    --ion-color-warning-rgb: 255,127,0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e07000;
    --ion-color-warning-tint: #ff8c1a;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #000000;
    --ion-color-dark-rgb: 0,0,0;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #000000;
    --ion-color-dark-tint: #1a1a1a;

    --ion-color-medium: #707070;
    --ion-color-medium-rgb: 112,112,112;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #636363;
    --ion-color-medium-tint: #7e7e7e;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255,255,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        --ion-color-primary: #ffed00;
        --ion-color-primary-rgb: 255,237,0;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0,0,0;
        --ion-color-primary-shade: #e0d100;
        --ion-color-primary-tint: #ffef1a;

        --ion-color-secondary: #0796d7;
        --ion-color-secondary-rgb: 7,150,215;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255,255,255;
        --ion-color-secondary-shade: #0684bd;
        --ion-color-secondary-tint: #20a1db;

        --ion-color-tertiary: #5260ff;
        --ion-color-tertiary-rgb: 82,96,255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255,255,255;
        --ion-color-tertiary-shade: #4854e0;
        --ion-color-tertiary-tint: #6370ff;

        --ion-color-success: #34b88d;
        --ion-color-success-rgb: 52,184,141;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0,0,0;
        --ion-color-success-shade: #2ea27c;
        --ion-color-success-tint: #48bf98;

        --ion-color-warning: #ff7f00;
        --ion-color-warning-rgb: 255,127,0;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0,0,0;
        --ion-color-warning-shade: #e07000;
        --ion-color-warning-tint: #ff8c1a;

        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235,68,90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255,255,255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;

        --ion-color-dark: #000000;
        --ion-color-dark-rgb: 0,0,0;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255,255,255;
        --ion-color-dark-shade: #000000;
        --ion-color-dark-tint: #1a1a1a;

        --ion-color-medium: #707070;
        --ion-color-medium-rgb: 112,112,112;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255,255,255;
        --ion-color-medium-shade: #636363;
        --ion-color-medium-tint: #7e7e7e;

        --ion-color-light: #ffffff;
        --ion-color-light-rgb: 255,255,255;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0,0,0;
        --ion-color-light-shade: #e0e0e0;
        --ion-color-light-tint: #ffffff;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-toolbar-background: #0d0d0d;

        --ion-item-background: #000000;
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1e1e1e;

        --ion-toolbar-background: #1f1f1f;

        --ion-tab-bar-background: #1f1f1f;
    }
}
